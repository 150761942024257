import React, {useState} from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import {
    TextInput, Group, Button, Select, NumberInput, Stack
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

const LedgerFormSchema = Yup.object().shape({
    quantity: Yup.number().min(1).required("Quantity is required")
})

const CONDITIONS = [
    {label: 'Pristine', value: 'Pristine'},
    {label: 'Reconditioning', value: 'Reconditioning'},
    {label: 'Scrap', value: 'Scrap'}
    // {label: 'Secondhand', value: 'Secondhand'}
]

const LedgerForm = ({initialValues, customerReasons, onAddNewLedgerClick}) => {
    const form = useForm({
        initialValues: initialValues,
        schema: yupResolver(LedgerFormSchema)
    })

    return (
        <form onSubmit={form.onSubmit((values) => {
            onAddNewLedgerClick(values)
            form.setValues({
                quantity: 1,
                condition: 'Reconditioning',
                reason: customerReasons[0] ? customerReasons[0].value : "",
                comment: ""
            })
        })}>
            <Stack>
                <Group>
                    <NumberInput
                        size='xs'
                        min={1}
                        required
                        label="Quantity"
                        placeholder=""
                        {...form.getInputProps('quantity')}
                    />
                    <Select data={CONDITIONS}
                            required
                            size='xs'
                            label="Condition"
                            placeholder=""
                            {...form.getInputProps('condition')}
                    />
                    <Select data={customerReasons}
                            required
                            size='xs'
                            label="Reason"
                            placeholder=""
                            {...form.getInputProps('reason')}
                    />
                    <Button type="submit" color='sgl' size='xs' mt='xl'>Save</Button>
                </Group>
                <Group grow={true}>
                    <TextInput size="xs" label="Comment" placeholder="" {...form.getInputProps('comment')} />
                </Group>
            </Stack>
        </form>
    )
}

LedgerForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    customerReasons: PropTypes.array.isRequired,
    onAddNewLedgerClick: PropTypes.func.isRequired
}

export default LedgerForm